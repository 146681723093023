.contact {
  background-color: var(--dark-grey);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.contact-content {
  width: 90%;
  height: fit-content;
  background-size: cover;
  background-position: center;
  border-radius: 0.8rem;
  box-shadow: 0 1.5rem 4rem rgb(0 0 0 / 20%);
  position: relative;
  overflow: hidden;
}

.contact-content_form {
  width: 100%;
  padding: 2rem 4rem;
}

.contact-title {
  margin-top: 0;
  margin-bottom: 1rem;
  text-align: center;
}

.form_group:not(:last-child) {
  margin-bottom: 1.5rem;
}

.form_input {
  font-size: 1.5rem;
  font-family: inherit;
  color: inherit;
  padding: 0.5rem 2rem;
  border-radius: 2px;
  border: none;
  background-color: rgba(255, 255, 255, 0.4);
  border-bottom: 3px solid transparent;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  width: 100%;
  display: block;
  transition: all 0.3s;
}

.form_input:focus {
  outline: none;
  border-bottom: 3px solid rgb(25, 189, 25);
}

.form_input:focus:invalid {
  border-bottom: 3px solid var(--secondary-color);
}

.submit-button {
  border: none;
  background-color: var(--primary-color);
  color: var(--white);
  padding: 1rem 2.5rem;
  border-radius: 2px;
  width: 100%;
}

.submit-button:hover {
  cursor: pointer;
  background-color: rgb(31, 31, 31);
}

@media (min-width: 768px) {
  .contact-content {
    width: 70%;
  }

  .contact-title {
    margin-bottom: 2rem;
    text-align: start;
  }

  .contact-content_form {
    width: 65%;
    padding: 2rem 4rem;
  }

  .form_input {
    padding: 0.5rem 2rem;
    width: 90%;
  }

  .submit-button {
    width: 90%;
  }
}
