.hero-content {
  position: relative;
  z-index: 1;
  height: 100vh;
}

.hero-image {
  width: 100%;
  height: 100vh;
  filter: brightness(30%);
}

.hero-text {
  color: var(--secondary-color);
  font-weight: 700;
  font-size: 4rem;
  line-height: 1;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.hero-phrase span {
  display: block;
}

@media (min-width: 768px) {
  .hero-text {
    font-size: 5rem;
  }

  .hero-phrase span {
    display: inline-block;
  }
}
