:root {
  --content-font: "Libre Franklin", sans-serif;
  --primary-color: #24272a;
  --secondary-color: #ff9e18;
  --light-grey: #f9f9f9;
  --dark-grey: #d9d8d6;
  --white: #fff;
  --black: #000;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; /*1rem = 10px*/
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: var(--content-font);
  font-size: 1.6rem;
  line-height: 2;
  background: var(--ligth-grey);
  margin: 0;
}

/*GLOBAL*/

.container {
  max-width: 120rem;
  width: 90%;
  margin: 0 auto;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4 {
  color: var(--secondary-color);
  font-family: var(--content-font);
  line-height: 1.2;
}

h1 {
  font-size: 4.8rem;
}

h2 {
  font-size: 4.2rem;
}

h3 {
  font-size: 3.2rem;
}

h4 {
  font-size: 2.8rem;
}

img {
  max-width: 100%;
}

/*UTILITIES*/

.no-margin {
  margin: 0;
}

.no-padding {
  padding: 0;
}

.center-text {
  text-align: center;
}
