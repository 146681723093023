.nav {
  background-color: var(--primary-color);
  height: 8.5rem;
  /* padding: 0 6.5rem; */
  position: fixed;
  z-index: 100;
}

.nav,
.nav-content {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.nav-content {
  margin: 0 3.5rem;
}

.nav-logo {
  width: 30%;
}

.nav-logo_image {
  width: 100%;
  height: auto;
}

.nav-content_link {
  color: var(--white);
  font-size: 1.2rem;
  font-weight: 500;
  transition: color 0.2s ease-in-out;
}

.nav-content_link:hover {
  color: var(--secondary-color);
}

.nav-content_link:not(:last-child) {
  margin-right: 1.5rem;
}

@media (min-width: 758px) {
  .nav-content {
    margin: 0 5.5rem;
  }

  .nav-logo {
    width: 15%;
  }

  .nav-content_link {
    font-size: 1.8rem;
  }

  .nav-content_link:not(:last-child) {
    margin-right: 3.5rem;
  }
}
