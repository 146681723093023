.about {
  height: 43rem;
  position: relative;
}

.about-title {
  color: white;
  font-size: 3.5rem;
  padding: 3rem 0;
  margin: 0;
  text-align: center;
  background-color: var(--secondary-color);
}

.about-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  top: 0;
  position: absolute;
  height: 100%;
}

.about-content_text {
  background-color: var(--primary-color);
  text-align: justify;
  color: white;
  padding: 0 2rem;
  top: 0;
  height: 100%;
  z-index: -1;
  font-size: 1rem;
}

.about-content_text p:not(:last-child) {
  margin-bottom: 0.5rem;
}

.first-p {
  margin-top: 13rem;
  display: inline-block;
}

.about-content_images {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 6rem;
}

.last-p {
  margin-top: 4.5rem;
  text-align: center;
}

.last-p em {
  font-weight: 500;
}

.last-p em span {
  display: block;
}

.about-photo {
  width: 50%;
  height: auto;
  box-shadow: 0 1.5rem 4rem rgba(var(--black), 0.4);
  border-radius: 0.8rem;
  position: absolute;
  z-index: 10;
  transition: all 0.2s ease-in-out;
  outline-offset: 2rem;
  z-index: 1;
}

.about-photo:hover {
  transform: scale(1.05) translateY(-0.5rem);
  box-shadow: 0 1.5rem 4rem rgba(var(--black), 0.5);
  z-index: 20;
}

.about-photo1 {
  left: 2.5rem;
  top: 21rem;
}

.about-photo2 {
  right: -3rem;
  top: 23rem;
}

.about-photo3 {
  left: 35%;
  top: 27rem;
}

@media (min-width: 768px) {
  .about {
    height: 100vh;
  }

  .about-title {
    padding: 5rem 0;
    font-size: 4.2rem;
  }

  .about-content_text {
    font-size: 1.6rem;
    padding: 1rem 6rem;
  }

  .first-p {
    margin-top: 25rem;
  }

  .last-p {
    margin-top: 6.5rem;
  }

  .about-photo {
    width: 35%;
  }

  .about-photo1 {
    left: 10rem;
    top: 22rem;
  }

  .about-photo2 {
    right: 0;
    top: 30rem;
  }

  .about-photo3 {
    left: 35%;
    top: 35rem;
  }
}
